export const state = () => ({
  form: null,
  colonies: null,
  apiError: null,
  access: false,
  targetLovotVisible: false,
  contactStep: 'start',
  aggregatedColonies: null,
  lovots: null,
  lovotGroup: null
})

export const getters = {
  targetLovotVisible: (state) => {
    return state.targetLovotVisible
  },
  contactForm: (state) => {
    return state.form
  },
  canAccess: (state) => {
    return state.access
  },
  apiError: (state) => {
    return state.apiError
  },
  contactStep: (state) => {
    return state.contactStep
  },
  cacheAggregatedColonies: (state) => {
    return state.aggregatedColonies
  },
  cacheLovots: (state) => {
    return state.lovots
  },
  lovotGroup: (state) => {
    return state.lovotGroup
  }
}

export const mutations = {
  targetLovotVisible: (state, visible) => {
    state.targetLovotVisible = visible
  },
  setContactForm(state, form) {
    state.form = form
    state.apiError = null
  },
  setAccessible(state, value = true) {
    state.access = value
  },
  setApiError(state, error) {
    state.access = true
    state.apiError = error
  },
  setContactStep: (state, step) => {
    state.contactStep = step
  },
  setAggregatedColonies: (state, colonies) => {
    state.aggregatedColonies = colonies
  },
  setLovots: (state, lovots) => {
    state.lovots = lovots
  },
  setContactLovotGroup: (state, lovotGroup) => {
    state.lovotGroup = lovotGroup
  },
  clear(state) {
    state.form = null
    state.colonies = null
    state.apiError = null
    state.access = false
    state.targetLovotVisible = false
    state.contactStep = 'start'
    state.lovotGroup = null
  }
}
